// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-tag-js": () => import("./../../../src/pages/tag.js" /* webpackChunkName: "component---src-pages-tag-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-tutorials-js": () => import("./../../../src/pages/tutorials.js" /* webpackChunkName: "component---src-pages-tutorials-js" */),
  "component---src-templates-post-list-js": () => import("./../../../src/templates/post-list.js" /* webpackChunkName: "component---src-templates-post-list-js" */),
  "component---src-templates-singlepost-js": () => import("./../../../src/templates/singlepost.js" /* webpackChunkName: "component---src-templates-singlepost-js" */),
  "component---src-templates-tag-page-js": () => import("./../../../src/templates/tag-page.js" /* webpackChunkName: "component---src-templates-tag-page-js" */)
}

